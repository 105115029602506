import {
    CookieBar,
    getSettings,
    getSelectedBlockedUrls,
    BlockCookies
} from './07-components/cookie-banner'

function onRun() {
    if (getSettings()) {
        BlockCookies(getSelectedBlockedUrls())
    } else {
        BlockCookies(getAllBlockedUrls())
    }
    // runs code as the js is parsed and initialised
}

function init() {
    // runs code when the full document has loaded, not all resources
    CookieBar()
}

function getAllBlockedUrls() {
    const blockCookies = window.blockCookies || []

    return Array.from(blockCookies).map((data) => {
        return data.url
    })
}

onRun()

if (document.readyState !== 'loading') {
    init()
} else {
    document.addEventListener('DOMContentLoaded', init)
}
